import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import TileCard from '../../components/TileCard';
import InfiniteScroll from '../../components/InfiniteScroll';
import Tag from '../../components/Tag';
import Divider from '../../components/Divider';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { ExerciseIndexQuery, ExerciseCardFragment } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './exercises-index.module.scss';

type ExercisePageContextType = GlobalPageContext & PaginationContext;

const ExerciseIndex: React.FC<PageProps<ExerciseIndexQuery, ExercisePageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3.id, ...websiteLocaleV3.attributes });
  const exercises: ExerciseCardFragment[] = get(data, 'strapi.exercises', []);
  const allExercisesWithCategories: ExerciseCardFragment[] = get(data, 'strapi.allExercisesWithCategories', []);
  const intl = useIntl();
  const pages = get(data, 'strapi.pages', []);

  if (!websiteLocale) return null;

  const uniqueTags = [
    ...new Set(
      map(compact(allExercisesWithCategories), ({ categories }) => map(compact(categories), ({ name }) => name)).flat(),
    ),
  ];

  const [list, setExerciseList] = useState<ExerciseCardFragment[]>(exercises);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [filteredExercises, setFilteredExercises] = useState<ExerciseCardFragment[]>([]);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);

  useEffect(() => {
    setExerciseList(exercises.slice(0, 10));
    setIsInitialLoadDone(true);
  }, [exercises]);

  useEffect(() => {
    let filtered: ExerciseCardFragment[] = [];
    if (selectedCategories.length > 0) {
      filtered = [...getFilteredExercises().slice(0, 10)];
    }
    setFilteredExercises(filtered);
  }, [selectedCategories]);

  const getFilteredExercises = () =>
    filter(compact(allExercisesWithCategories), (exercise: ExerciseCardFragment) =>
      compact(exercise.categories).some((cat) => selectedCategories.includes(cat.name ?? '')),
    );

  const handleCategoryClick = (tag: string) => {
    let newSelectedTags: string[] = [];
    if (selectedCategories.includes(tag)) {
      newSelectedTags = selectedCategories.filter((e) => e !== tag);
      setSelectedCategories([...new Set(newSelectedTags)]);
    } else {
      newSelectedTags = [...selectedCategories, tag];
      setSelectedCategories([...new Set(newSelectedTags)]);
    }
  };

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.exercises.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ??  intl.formatMessage({ id: 'seo.exercises.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <h1 className="title color-a mb-0">
          <FormattedMessage id="exercisesIndexHeading" defaultMessage="English Exercises" />
        </h1>
        <Divider paddingTop="75px" marginBottom="80px" />
        {uniqueTags.length ? (
          <div className="content">
            <h5 className="title brand-a">
              <FormattedMessage id="categoriesText" defaultMessage="Categories" />
            </h5>
            <p className="tag-list is-text-1">
              {map(uniqueTags, (name, index) => (
                <Tag
                  isActive={selectedCategories?.includes(name)}
                  onClick={() => handleCategoryClick(name)}
                  isClickable={true}
                  name={name}
                  key={`${name}${index}`}
                />
              ))}
            </p>
          </div>
        ) : null}
        {!exercises?.length ? (
          <div className="column is-5 p-0 mt-10">
            <h4 className="title is-4">
              <FormattedMessage id="noExercisesIndexHeading" />
            </h4>
          </div>
        ) : null}
        <div className={styles.gridContainer}>
          {selectedCategories.length > 0
            ? compact(filteredExercises).map(
                ({ id, title, slug, excerpt, image, publishedAt, categories, showDate, originalDate }) => {
                  return (
                    <TileCard
                      linkText={intl.formatMessage({ id: 'exercisesCardText', defaultMessage: 'View Exercise' })}
                      slugBase={pageContext.pathPrefixes.exercises}
                      key={`exercise-${id}`}
                      variant="exercise"
                      title={title ?? 'N/A'}
                      slug={slug ?? ''}
                      image={image?.url}
                      categories={compact(categories)}
                      short={excerpt ?? ''}
                      date={originalDate ?? publishedAt}
                      selectedCategories={selectedCategories}
                      showDate={showDate}
                    />
                  );
                },
              )
            : compact(list).map(
                ({ id, title, slug, excerpt, image, publishedAt, categories, showDate, originalDate }) => {
                  return (
                    <TileCard
                      linkText={intl.formatMessage({ id: 'exercisesCardText', defaultMessage: 'View Exercise' })}
                      slugBase={pageContext.pathPrefixes.exercises}
                      key={`exercise-${id}`}
                      variant="exercise"
                      title={title ?? 'N/A'}
                      slug={slug ?? ''}
                      image={image?.url}
                      categories={compact(categories)}
                      short={excerpt ?? ''}
                      date={originalDate ?? publishedAt}
                      selectedCategories={selectedCategories}
                      showDate={showDate}
                    />
                  );
                },
              )}
        </div>
        {isInitialLoadDone && (
          <InfiniteScroll
            selectedCategories={selectedCategories}
            categoryList={getFilteredExercises()}
            filteredList={filteredExercises}
            completeList={exercises}
            list={list}
            setList={(list) => setExerciseList(list)}
            setFilteredList={(filteredList) => setFilteredExercises(filteredList)}
          />
        )}
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {get(websiteLocale, 'footerCta', null) && <StrapiFooterCtaComponent {...get(websiteLocale, 'footerCta', null)} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(ExerciseIndex));

export const query = graphql`
fragment ExerciseBase on Strapi_Exercise {
  title
  publishedAt
  showDate: show_date
  originalDate: original_date
  categories {
    data {
      id
      attributes {
        name
      }
    }
  }
  image {
    ...StrapiImageEntityResponse
  }
}

fragment ExerciseCard on Strapi_Exercise {
  ...ExerciseBase
  slug
  excerpt
}

query ExerciseIndex($websiteLocaleId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    allExercisesWithCategories: exercises(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}, categories: {not: null}}
      locale: $locale
      pagination: {limit: -1}
    ) {
      data {
        id
        attributes {
          ...ExerciseCard
        }
      }
    }
    exercises(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}}
      locale: $locale
      sort: "publishedAt:desc"
      pagination: {limit: -1}
    ) {
      data {
        id
        attributes {
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          ...ExerciseCard
          seo {
            ...SEOComponent
          }
        }
      }
    }
    pages(
      filters: {website_locale: {id: {eq: $websiteLocaleId}}, index_page: {eq: "Exercises"}}
      locale: $locale
    ) {
      data {
        id
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
